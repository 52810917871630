


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";

import { BASE_API_URL } from "@/config";
import { VueEditor } from "vue2-editor";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
@Component({ components: { VueEditor } })
export default class RequestTitleOrderReport extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public files = [];
  public linkExpire = false;
  public comment = null;

  /**
   * Verifing
   */
  public async verifyTokenForRequestOrderTitleReport() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "authentication/verifyTokenForRequestOrderTitleReport",
        {
          token: this.$route.query.id,
          loanTxnId: this.$route.query.loanTxnId
        }
      );
      if (response.data.msg == "success") {
      } else {
        this.linkExpire = true;
        this.$snotify.error("Your link has expired");
      }
    } catch (error) {}
  }

  /**
   * Save Data
   */
  public async saveTitleOrderReportsData() {
    this.$store.state.wemloLoader = true;
    try {
      if (this.files.length == 0) {
        this.$snotify.error("Please choose file");
        this.$store.state.wemloLoader = false;

        return;
      }

      let formData = new FormData();
      formData.append("loanTxnId", JSON.stringify(this.$route.query.loanTxnId));
      formData.append("comment", JSON.stringify(this.comment));

      this.files.forEach(file => {
        formData.append("file", file);
      });
      let response = await Axios.post(
        BASE_API_URL + "titleOrder/saveTitleOrderReportsData",
        formData
      );
      this.$snotify.success("Data Saved Successfully");
      this.$router.push("/");
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  public fileUploads(event) {
    try {
      Object.values(event.target.files).forEach(e => {
        this.files.push(e);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.verifyTokenForRequestOrderTitleReport();
  }
}
